
#root, .App {
  height: 100%;
  width: 100%;
}

.leaflet-container {
  /* height: 100%;
  width: 100%;
  position: absolute; */
}